html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;

  overflow-x: hidden;
}

@media only screen and (max-width:  768px) { html { font-size: 12px; } }
@media only screen and (min-width:  769px) { html { font-size: 13px; } }
@media only screen and (min-width: 1024px) { html { font-size: 14px; } }
@media only screen and (min-width: 1200px) { html { font-size: 15px; } }
@media only screen and (min-width: 1400px) { html { font-size: 16px; } }

body {
  background-color: #F9F3EB;
}

* {
  font-family: 'proxima-nova', sans-serif;
  text-rendering: optimizeLegibility;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
